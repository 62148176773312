export default function CutFluff(props) {
  return (
    <>
      <div className={"m-2 " + props.className}>
        <p>
          <span className="text-gray-500/80 line-through">
            The thing about productivity tools is that they hold the potential
            to free up your time to focus on the things that matter.
          </span>{" "}
          <span className="text-xl font-extrabold text-white">Cut</span>
          <span className="text-gray-500/80 line-through">
            ting out filler words & nonsense to see
          </span>{" "}
          <span className="text-xl font-extrabold text-white">through </span>
          <span className="text-gray-500/80 line-through">
            to what is meaningful - the real point, behind all
          </span>{" "}
          <span className="text-xl font-extrabold text-white">the fluff</span>
          <span className="text-gray-500/80 line-through">
            , is what SummerEyes can do for you.
          </span>
        </p>
      </div>
    </>
  );
}
