import Head from "next/head";
import React from "react";
import Link from "next/link";
import Image from "next/image";

import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroVideo from "../components/HeroVideo";
import PageDefault from "../components/PageDefault";
import Hero from "../components/pageUI/Hero";
import ContentPlacer from "../components/ContentPlacer";
import ShiftingGradients from "../components/pageUI/ShiftingGradientsBg";
import ShiftingGradientsBg from "../components/pageUI/ShiftingGradientsBg";
import BenefitsGrid from "../components/pageUI/BenefitsGrid";
import PricingSection from "../components/pageUI/PricingSection";
import TestimonialsSection from "../components/pageUI/TestimonialsSection";
import UseCaseSection from "../components/pageUI/UseCaseSection";
import BottomCTA from "../components/pageUI/BottomCTA";
import FeatureHighlight from "../components/pageUI/FeatureHighlight";
import WavyBackground from "../components/pageUI/WavyBackground";

export default function Home() {
  return (
    <>
      <PageDefault
        pageTitle="SummerEyes: AI-powered summaries in your browser"
        setBackground="bg-summer-base"
        isDark={true}
      >
        <ShiftingGradientsBg />
        <section className=" w-full pt-14 pb-52 md:pb-80 md:pt-28">
          <ContentPlacer>
            <Hero />
          </ContentPlacer>
        </section>

        <div className="bg-slate-100 pt-48">
          <ContentPlacer>
            <HeroVideo />
          </ContentPlacer>
        </div>
        <div className="-mt-48 bg-slate-100 pt-12 md:pt-48">
          <ContentPlacer>
            <BenefitsGrid />
            <TestimonialsSection />
            <FeatureHighlight />
            <BottomCTA />
          </ContentPlacer>
        </div>
        <div className="-mt-48 h-96 w-full bg-wavy bg-cover"></div>
      </PageDefault>
    </>
  );
}
