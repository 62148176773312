import React from "react";
import {
  ArrowRightIcon,
  LoginIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/outline";
import Link from "next/link";
import SummerEyesButton from "../branding/SummerEyesButton";

// INTERIM: Get the HeroVideo working properly
export default function Hero() {
  return (
    <>
      <div className="z-50 mx-auto w-full">
        <div className="grid grid-cols-12 ">
          <div className="md:items-left col-span-12 w-full md:col-span-9 md:mx-auto md:flex md:text-left">
            <h1 className="text-4xl font-medium text-white md:text-6xl">
              Summarize any text <br className="hidden md:inline" />
              on the internet <br />
              <span className="text-summer-contrast-highlight underline decoration-summer-contrast-highlight decoration-wavy underline-offset-8">
                in one click
              </span>
            </h1>
          </div>

          <div className="col-span-12 md:col-span-3">
            <div className="flex items-center pt-8 pb-0 md:hidden">
              <div className="h-[1.5px] flex-grow bg-slate-100/40"></div>

              <ChevronDoubleDownIcon className="mx-[4px] h-[10px] w-[10px] flex-shrink font-bold uppercase text-slate-100/60" />

              <div className="h-[1.5px] flex-grow bg-slate-100/40"></div>
            </div>
            <div className="h-full justify-center align-middle md:order-2 ">
              <div className="py-4 md:h-full md:px-0 ">
                <h3 className="relative z-50 text-slate-100 ">
                  Supercharge your productivity.
                </h3>
                <h5 className="relative z-50 text-slate-100">
                  <span className="font-bold text-summer-light-highlight-600">
                    Get to the point
                  </span>{" "}
                  in a fraction of the time
                </h5>

                <SummerEyesButton customTailwind="my-5" full href="/login">
                  <LoginIcon className="absolute left-0 flex h-5 items-center pl-8 md:hidden xl:flex" />
                  Get started
                </SummerEyesButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
