import React from "react";

export default function ShiftingGradientsBg(props) {
  return (
    <>
      <div className="absolute top-0 left-0 -z-20 -mt-28 h-[880px] w-full bg-heroBgStatic bg-cover pt-28">
        <div className="relative -z-30 -mt-28 w-full">{props.children}</div>
      </div>

      {/* INTERIM: All moving backgrounds deactivated temporarily for performance reasons
      
          <div className="absolute top-0 -left-4 -z-10 h-72 w-[440px] animate-blob4 rounded-full bg-summer-bright mix-blend-hard-light blur-3xl filter"></div>
          <div className="animation-delay-4000 absolute bottom-20  -left-4 -z-10 h-72 w-72 animate-blob1 rounded-full bg-summer-contrast-highlight mix-blend-hard-light blur-3xl filter"></div>
          <svg
            width="720"
            height="720"
            viewBox="0 0 720 720"
            xmlns="http://www.w3.org/2000/svg"
            className="animation-delay-4000 rounded-ful absolute  bottom-20 -left-4 -z-10 h-72 w-72 animate-blob1 mix-blend-hard-light blur-3xl filter"
          >
            <rect width="720" height="720" fill="none" />
            <path
              d="M535.8331761840561,409.99999999999994C538.2601802009533,456.7052083909224,575.0495190136224,458.9574457628138,553.7439896496131,484.13222457856136C532.4384602856038,509.30700339430894,450.5862307920133,556.6153824384377,408,561.0486728944852C365.4137692079867,565.4819633505327,334.8932715642,535.7400794639273,298.2266048975333,510.7319673148464C261.55993823086663,485.7238551657655,196.53043302342442,460.97183218030324,188,411C179.46956697657558,361.02816781969676,213.0440067569868,242.95467684311217,247.0440067569868,210.90097423302683C281.0440067569868,178.8472716229415,343.31034020145944,219.84445100615463,391.99999999999994,218.67778433948794C440.68965979854045,217.51111767282126,515.2097695175537,172.01393828960812,539.1819655482298,203.90097423302677C563.1541615789058,235.78801017644543,533.406172167159,363.2947916090775,535.8331761840561,409.99999999999994C538.2601802009533,456.7052083909224,575.0495190136224,458.9574457628138,553.7439896496131,484.13222457856136"
              fill="#4CFA9D"
            />
          </svg>
          <svg
            width="1920"
            height="1080"
            viewBox="0 0 1920 1080"
            xmlns="http://www.w3.org/2000/svg"
            className="animation-delay-2000 absolute -top-16 right-1/4 -z-10 h-96 w-96 animate-blob2 rounded-full bg-summer-bright mix-blend-hard-light blur-3xl filter"
          >
            <rect width="1920" height="1080" fill="none" />
            <path
              d="M1580,549.9999999999999C1588.4573126620228,659.2009221076446,1511.7640687119285,921.4877337360267,1415.2640687119285,993.2640687119285C1318.7640687119285,1065.0404036878303,1118.6351026096268,976.9913431887439,1001,980.6580098554106C883.3648973903731,984.3246765220772,778.0693963686394,1087.8737370211636,709.4534530541673,1015.2640687119285C640.8375097396952,942.6544004026935,615.9239270741832,660.915666258559,589.3043401131672,545C562.6847531521512,429.08433374144107,487.4533213069327,405.5195008876556,549.7359312880715,319.77007116057496C612.0185412692102,234.0206414334943,827.2026230913799,27.45534415326847,962.9999999999999,30.503421637516C1098.79737690862,33.55149912176353,1261.6868594064583,251.47577300564615,1364.5201927397916,338.0585360660601C1467.3535260731248,424.6412991264741,1571.5426873379772,440.79907789235517,1580,549.9999999999999C1588.4573126620228,659.2009221076446,1511.7640687119285,921.4877337360267,1415.2640687119285,993.2640687119285"
              fill="#0F6FFF"
            />
          </svg>
          <div className="animation-delay-2000 absolute -top-16 right-1/4 -z-10 h-96 w-96 animate-blob2 rounded-full bg-summer-bright mix-blend-hard-light blur-3xl filter"></div>
          <div className="absolute -top-4 right-1/4 -z-10 h-48 w-48 animate-blob3 rounded-full bg-summer-contrast-highlight mix-blend-hard-light blur-3xl filter"></div>
          <div className="animation-delay-4000 absolute -top-8 right-12 -z-10 h-96 w-96 animate-blob4 rounded-full bg-summer-bright mix-blend-hard-light blur-3xl filter"></div>
          <div className="pt-28">{props.children}</div>
        </div>
      </div>
  */}
    </>
  );
}
