import { PuzzleIcon } from "@heroicons/react/solid";
import React from "react";

export default function HeroVideo() {
  return (
    <>
      <div className="video-box-fill mx-auto -mt-96 h-64 w-full rounded-sm px-5 pb-5 pt-14 md:h-[660px] xl:h-[720px]">
        <div className="-mt-7 flex w-full pb-5">
          <div className="row flex">
            <div className="h-3 w-3 rounded-full bg-summer-light-highlight" />
            <div className="mx-1 h-3 w-3 rounded-full bg-summer-contrast-highlight" />
            <div className="h-3 w-3 rounded-full bg-summer-light-highlight" />
          </div>
          <div className="grid w-full justify-items-end">
            <div className="float-right -mb-2 -mt-4 justify-end justify-self-end rounded-lg bg-slate-100/50 p-2">
              <PuzzleIcon className="h-5 text-white" />
              {/*INTERIM: Create an Easter egg for users who click on this (and track it!) */}
            </div>
          </div>
        </div>
        <div className="h-full rounded-xl">
          <div className="relative h-full w-full rounded-xl">
            <iframe
              src="https://player.vimeo.com/video/768675358??background=1&amp;autoplay=1&amp;h=190226c1dd&amp;badge=0&amp;muted=1&amp;autopause=0&amp;loop=1&amp;quality=1080p&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              autoPlay="1"
              title="SummerEyes Product Overview Video"
              className="absolute left-0 mb-24 h-full w-full rounded-xl object-scale-down"
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>
    </>
  );
}
