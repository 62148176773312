import React from "react";
import Image from "next/image";
import SummerEyesButton from "../branding/SummerEyesButton";
import { ChipIcon } from "@heroicons/react/solid";

export default function BenefitsGrid(props) {
  return (
    <>
      <div className="mt-36 mb-16 flex flex-wrap gap-5 md:mt-48 md:grid md:h-[420px] md:grid-flow-col md:grid-rows-5 md:gap-2">
        <div className="row-span-3 min-h-[270px] w-full rounded-xl border border-slate-300 bg-white/70 shadow-md  hover:bg-slate-200/20 md:min-h-0">
          <div className="flex h-full w-full">
            <div className="my-auto flex w-full flex-wrap">
              <div className="flex w-full">
                <div className="mx-auto pt-6 md:mx-2 xl:mx-auto">
                  <Image
                    src="/img/timeSavingsChart.svg"
                    alt="Time Saved by SummerEyes"
                    width={211}
                    height={75}
                    className="mx-auto flex h-full w-full md:mx-2 xl:mx-auto"
                  />
                </div>
              </div>

              <p className="w-full px-8 pt-4 text-center text-6xl font-bold text-summer-light-highlight-700 md:text-3xl xl:text-6xl">
                <span className="bg-gradient-to-r from-summer-light-highlight to-summer-bright bg-clip-text text-transparent">
                  70%
                </span>
              </p>

              <p className="w-full px-8 text-center text-lg font-medium leading-tight text-gray-700">
                faster average reading speed
              </p>
            </div>
          </div>
        </div>
        <div className="row-span-2 min-h-[270px] w-full rounded-xl border border-slate-300 bg-white/70 shadow-md  hover:bg-slate-200/20 md:min-h-0 ">
          <div className="flex h-full w-full">
            <div className="my-auto flex w-full flex-wrap">
              <p className="w-full px-8 text-center text-6xl font-bold text-summer-light-highlight-700 md:text-3xl xl:text-6xl">
                <span className="bg-gradient-to-r from-summer-contrast-highlight to-summer-bright bg-clip-text text-transparent">
                  30 mins
                </span>
              </p>

              <p className="w-full px-8 text-center text-lg font-medium leading-tight text-gray-700">
                Saved per day by average user<sup> *</sup>
              </p>
            </div>
          </div>{" "}
        </div>
        <div className=" order-first row-span-5 min-h-[270px] w-full justify-items-center rounded-xl border border-slate-300 bg-white/70 shadow-md  hover:bg-slate-200/20 md:order-none md:min-h-0">
          <div className="w-full ">
            <div className="px-auto grid grid-cols-2 gap-6 pt-8">
              <div className="flex h-24 w-24 justify-self-end rounded-xl bg-slate-300/50 shadow-sm ">
                <div className="m-auto ">
                  <Image
                    src="/img/chromeIcon.png"
                    alt="Chrome"
                    width={70}
                    height={70}
                    className="container mx-auto"
                  />
                </div>
              </div>
              <div className="flex h-24 w-24 justify-self-start rounded-xl bg-slate-300/50 shadow-sm">
                <div className="m-auto">
                  <Image
                    src="/img/braveIcon.png"
                    alt="Brave"
                    width={65}
                    height={65}
                    className="h-full w-full"
                  />
                </div>
              </div>
              <div className="flex h-24 w-24 justify-self-end rounded-xl bg-slate-300/50 shadow-sm">
                <p className="absolute z-20 mx-1 self-center rounded-full bg-summer-base/30 py-2 px-2 text-center text-xs text-white ">
                  Coming soon
                </p>
                <div className="m-auto grayscale">
                  <Image
                    src="/img/safariIcon.png"
                    alt="Safari - Coming Soon"
                    width={70}
                    height={70}
                    className="braveIcomx-auto container"
                  />
                </div>
              </div>
              <div className="flex h-24 w-24 justify-self-start rounded-xl bg-slate-300/50 shadow-sm">
                <p className="absolute z-20 mx-1 self-center rounded-full bg-summer-base/30 py-2 px-2 text-center text-xs text-white ">
                  Coming soon
                </p>
                <div className="m-auto grayscale">
                  <Image
                    src="/img/firefoxIcon.png"
                    alt="Firefox - Coming Soon"
                    width={65}
                    height={65}
                    className="h-full w-full"
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full flex-wrap">
              <p className="w-full px-8 pt-4 text-center text-3xl font-bold text-gray-700 md:text-2xl xl:text-3xl">
                <span className="bg-gradient-to-br from-summer-contrast-highlight to-summer-bright bg-clip-text text-transparent">
                  Available on major desktop browsers
                </span>
              </p>
              <SummerEyesButton customTailwind="my-4 xl:my-4 md:my-2">
                {/*INTERIM: Apply the proper button stuff here (hovers & the like) & point to the right destination*/}
                Install now
              </SummerEyesButton>
            </div>
          </div>
        </div>
        <div className="order-last row-span-2 min-h-[270px] w-full rounded-xl border border-slate-300 bg-white/70 shadow-md hover:bg-slate-200/20 md:order-none md:min-h-0 ">
          <div className="flex h-full w-full">
            <div className="my-auto flex w-full flex-wrap">
              <div className="flex w-full">
                <div className="mx-auto pt-2">
                  <Image
                    src="/img/ITA.png"
                    alt="In Browser Icon"
                    width={254}
                    height={79}
                    className="-m-6 mx-auto flex h-full w-full"
                  />
                </div>
              </div>

              <p className="w-full px-8 text-center text-lg font-medium leading-tight text-gray-700">
                There when you need it. Hidden when you don't.
              </p>
            </div>
          </div>
        </div>
        <div className=" row-span-3 min-h-[270px] w-full rounded-xl border border-slate-300 bg-white/70 shadow-md hover:bg-slate-200/20 md:min-h-0 ">
          <div className="flex h-full w-full">
            <div className="my-auto flex w-full flex-wrap">
              <p className="w-full px-8 text-center text-6xl font-bold text-summer-light-highlight-700 md:text-3xl xl:text-6xl">
                <span className="bg-gradient-to-r from-summer-light-highlight to-summer-bright bg-clip-text text-transparent">
                  400 Million
                </span>
              </p>

              <p className="w-full px-8 text-center text-lg font-medium leading-tight text-gray-700">
                Parameters powering our cutting-edge AI models
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
