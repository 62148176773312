import Image from "next/image";
import CutFluff from "./CutFluff";
import { ChevronDoubleDownIcon } from "@heroicons/react/solid";

export default function FeatureHighlight(props) {
  return (
    <>
      <section className="relative mt-20 bg-slate-100 pb-40 md:mt-48">
        <div className="container mx-auto pt-4" id="features-segment">
          <div className="flex flex-wrap items-center">
            <div className="-mt-26 w-full md:w-6/12 xl:mr-12 xl:w-4/12">
              <div className="shadow-3xl relative mb-6 flex w-full min-w-0 flex-col break-words  rounded-lg border bg-summer-base">
                <CutFluff className="px-8 py-4" />
                <hr className="mx-9 h-px border-0 bg-gray-200 dark:bg-gray-700" />

                <blockquote className="relative mb-4 p-8">
                  <h4 className="text-xl font-medium text-white">
                    Get to the point. Fast.
                  </h4>
                  <p className="text-md mt-2 font-light text-gray-200">
                    Turbocharge your productivity with an integrated, automated
                    tool that let's you process far more information, far more
                    quickly. From articles, to emails, cut through the BS, and
                    find what matters.
                  </p>
                </blockquote>
              </div>
            </div>
            <div className="w-full md:w-6/12 xl:w-7/12">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 md:px-4">
                  <div className="relative mt-4 flex flex-col">
                    <div className="flex-auto px-4 py-5">
                      <div className="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center text-slate-500 shadow-lg">
                        <i className="fas fa-tachometer-alt"></i>
                      </div>
                      <h6 className="mb-1 text-xl font-medium">
                        Blazingly fast
                      </h6>
                      <p className="mb-4 text-slate-500">
                        Summaries, instantly. Reduce 15 minute articles to 30
                        second highlights in a flash.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className="relative flex min-w-0 flex-col">
                    <div className="flex-auto px-4 py-5">
                      <div className="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center text-slate-500 shadow-lg">
                        <i className="fas fa-hand-holding-heart"></i>
                      </div>
                      <h6 className="mb-1 text-xl font-medium">
                        Ridiculously convenient
                      </h6>
                      <p className="mb-4 text-slate-500">
                        One click, right there in your browser. No need to
                        switch apps, or copy-paste.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 md:px-4">
                  <div className="relative mt-4 flex min-w-0 flex-col">
                    <div className="flex-auto px-4 py-5">
                      <div className="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center text-slate-500 shadow-lg">
                        <i className="fas fa-meteor"></i>
                      </div>
                      <h6 className="mb-1 text-xl font-medium">
                        Incredibly powerful
                      </h6>
                      <p className="mb-4 text-slate-500">
                        Leveraging the latest innovations in Natural Language
                        Processing to read, understand, and re-write.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex min-w-0 flex-col">
                    <div className="flex-auto px-4 py-5">
                      <div className="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center text-slate-500 shadow-lg">
                        <i className="fas fa-drafting-compass"></i>
                      </div>
                      <h6 className="mb-1 text-xl font-medium">
                        Unbelievably useful
                      </h6>
                      <p className="mb-4 text-slate-500">
                        Read every newsletter, understand every article, and
                        skim every company-wide email.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center pt-8 pb-0 md:hidden">
          <div className="h-[1.5px] flex-grow bg-slate-400"></div>

          <ChevronDoubleDownIcon className="mx-[4px] h-4 w-4 flex-shrink font-bold uppercase text-slate-400" />

          <div className="h-[1.5px] flex-grow bg-slate-400"></div>
        </div>

        <div className="container mx-auto pt-16 md:px-4 md:pb-16 md:pt-32">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto w-full md:w-5/12 md:px-4">
              <div className="md:pr-12">
                <div className="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-white p-3 text-center text-slate-500 shadow-lg">
                  <i className="fas fa-download text-xl"></i>
                </div>
                <h3 className="text-3xl font-medium">
                  Easily installable and available in your browser
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-slate-500">
                  Implemented as a Chrome extension, SummerEyes is fully
                  functional on any device supporting the extension ecosystem.
                  Install once, and AI-powered summaries are instantly
                  available.
                </p>
              </div>
            </div>

            <div
              className="mr-auto w-full px-4 pt-24 md:w-6/12 md:pt-0"
              style={{
                transform:
                  "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
              }}
            >
              <Image
                alt="An example of SummerEyes as a browser extension"
                className="max-w-full rounded-lg shadow-xl"
                width={891}
                height={617}
                src="/img/InTabFT.png"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
